import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Oval } from "react-loader-spinner";
import InputMask from "react-input-mask";

const RequestDemoForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    role: "",
    useCase: "",
    transferNumber: "",
    model: "",
    promptType: "",
    voiceId: "", 
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [callId, setCallId] = useState(null);
  const [voices, setVoices] = useState([]);
  const [promptTypes, setPromptTypes] = useState([]);

  useEffect(() => {
    const fetchVoices = async () => {
      try {
        const response = await axios.get("https://api.bland.ai/v1/voices", {
          headers: {
            Authorization: process.env.REACT_APP_BLAND_API,
          },
        });

        if (response.data && response.data.voices) {
          const voiceOptions = response.data.voices.map((voice) => {
            let isReducedLatency = voice.reduce_latency;

            // Force 'Grace (Southern)' to have reduced_latency set to false
            if (voice.name.toLowerCase().includes("grace-southern")) {
              isReducedLatency = false;
            }

            return {
              id: voice.voice_id,
              name: voice.name,
              reduced_latency: isReducedLatency,
            };
          });

          setVoices(voiceOptions);
        }
      } catch (error) {
        console.error("Error fetching voices:", error);
      }
    };

    const fetchPromptTypes = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/prompts-api`
        );
        setPromptTypes(
          response.data.map(({ type, description }) => ({
            value: type,
            description,
          }))
        );
      } catch (error) {
        console.error("Error fetching prompt types:", error);
      }
    };

    fetchVoices();
    fetchPromptTypes();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const sendPhoneCall = async () => {
    setIsSubmitting(true);
    try {
      const selectedVoice = voices.find(
        (voice) => voice.id === parseInt(formData.voiceId)
      );
      let isReducedLatency = selectedVoice ? selectedVoice.reduced_latency : false;

      // Specific check for the "grace-southern" voice
      if (
        selectedVoice &&
        selectedVoice.name.toLowerCase() === "grace-southern"
      ) {
        isReducedLatency = false;
      }

      const dataToSend = {
        ...formData,
        transferNumber: formData.transferNumber ? formData.transferNumber : null,
        model: formData.model,
        reduced_latency: isReducedLatency, 
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/request-demo`,
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast("Phone call sent!", { type: "success" });
      setCallId(response.data.call_id);
    } catch (error) {
      toast("Error dispatching phone call!", { type: "error" });
      console.error("Error:", error.response || error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    sendPhoneCall();
  };

    // Adjusted Styles
    const inputStyle = "mt-1 block w-11/12 mx-auto border-gray-300 px-4 py-3 rounded-lg focus:border-blue-500 focus:outline-none text-white bg-gray-700";
    const labelStyle = "text-gray-800 text-sm font-semibold mb-1";
    const dropdownStyle = "mt-1 block w-full border-2 border-gray-300 shadow-sm px-4 py-3 rounded-lg focus:border-blue-500 focus:outline-none bg-white";
    const buttonStyle = "mt-4 px-6 py-2 font-medium tracking-wide text-white capitalize transition duration-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80 shadow-md transform transition duration-200 flex justify-center items-center cursor-pointer";
    const buttonBackground = "bg-blue-600 hover:bg-blue-700"; // Ensure there's a change in the hover state

    return (
      <div className="flex flex-col justify-center items-center min-h-screen px-4">
        <form onSubmit={handleSubmit} className="flex flex-col gap-4 px-8 py-5 bg-white rounded-lg shadow-md z-20 w-full max-w-md mx-auto">
          <ToastContainer />

       <label className={labelStyle}>Name
        <input
          type="text"
          name="name"
          onChange={handleChange}
          placeholder="Enter your name"
          className={inputStyle}
          required
        />
      </label>

       <label className={labelStyle}>Email
        <input
          type="email"
          name="email"
          onChange={handleChange}
          placeholder="Enter your email"
          className={inputStyle}
          required
        />
      </label>

       <label className={labelStyle}>Phone Number
        <InputMask
          mask="+9 999 999 9999"
          maskChar={null} // Makes additional characters optional
          value={formData.phoneNumber}
          onChange={handleChange}
          placeholder="+1 234 567 8900"
          className={inputStyle}
          name="phoneNumber"
          required
        />
      </label>

       <label className={labelStyle}>Transfer Number
          <InputMask
            mask="+9 999 999 9999"
            maskChar={null} // Makes additional characters optional
            value={formData.transferNumber}
            onChange={handleChange}
            placeholder="+1 234 567 8900"
            className={inputStyle}
            name="transferNumber"
            optional
          />
        </label>


       <label className={labelStyle}>Company Name
        <input
          type="text"
          name="companyName"
          onChange={handleChange}
          placeholder="Enter your company name"
          className={inputStyle}
          required
        />
      </label>

       <label className={labelStyle}>
          Role
          <select
            name="role"
            onChange={handleChange}
            className={dropdownStyle}
            required
          >
          <option value="">Select</option>
          <option value="ceo">CEO/Founder</option>
          <option value="cto">CTO</option>
          <option value="cfo">CFO</option>
          <option value="vp of sales">VP of Sales</option>
          <option value="sales manager">Sales Manager</option>
          <option value="marketing manager">Marketing Manager</option>
          <option value="hr manager">HR Manager</option>
          <option value="it manager">IT Manager</option>
          <option value="product manager">Product Manager</option>
          <option value="customer service manager">
            Customer Service Manager
          </option>
          <option value="developer">Software Developer</option>
          <option value="data analyst">Data Analyst</option>
        </select>
      </label>

       <label className={labelStyle}>
          Use Case
          <select
            name="useCase"
            onChange={handleChange}
            className={dropdownStyle}
            required
          >
          <option value="">Select</option>
          <option value="inbound and outbound sales">Inbound/Outbound Sales</option>
          <option value="customer service and support">Customer Service and Support</option>
          <option value="market research and surveys">Market Research and Surveys</option>
          <option value="hospitality reservations and reminders">Hospitality Reservations and Reminders</option>
          <option value="education">Education</option>
          <option value="financial services">Financial Services</option>
          <option value="personal assistant">Personal Assistant</option>
          <option value="insurance inquiries">Insurance Inquiries</option>
          <option value="legal inquiries">Legal Inquiries</option>
          <option value="feedback collection and analysis">Feedback Collection and Analysis</option>
         </select>
      </label>
       
<label className={labelStyle}>
        Voice Options
        <select
          name="voiceId"
          onChange={handleChange}
          className={dropdownStyle}
          required
        >
          <option value="">Select</option>
          {voices.map((voice) => (
            <option key={voice.id} value={voice.id}>
              {voice.name} - {voice.reduced_latency ? "Reduced Latency" : "Standard"}
            </option>
          ))}
        </select>
      </label>

      {/* Model Dropdown (Tooltip Removed) */}
      <label className={labelStyle}>
        Model 
        <select
          name="model"
          value={formData.model}
          onChange={handleChange}
          className={dropdownStyle}
          required
        >
          <option value="">Select Model</option>
          {/* Populate this with your actual model options */}
          <option value="base">Base</option>
          <option value="enhanced">Enhanced</option>
          <option value="turbo">Turbo</option>          
        </select>
      </label>

        {/* Dropdown for selecting prompt type */}
         <label className={labelStyle}>
        Select Agent Type
            <select
                name="promptType"
                value={formData.promptType}
                onChange={handleChange}
                className={dropdownStyle}
                required
            >
                <option value="">Select</option>
                {promptTypes.map(({ value, description }) => (
                    <option key={value} value={value}>
                        {description}
                    </option>
                ))}
            </select>
        </label>

        <button
        type="submit"
        style={{ backgroundColor: isSubmitting ? '#93c5fd' : '#3b82f6' }}
        className={`mt-4 ${buttonStyle} ${buttonBackground} shadow-md transform transition duration-200 flex justify-center items-center cursor-pointer`}
        disabled={isSubmitting}
      >
        {isSubmitting && (
          <Oval
            height={16}
            width={16}
            color="#fff"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#fefefe"
            strokeWidth={4}
            strokeWidthSecondary={4}
          />
        )}
        <span className="ml-2">Request Demo</span>
      </button>
    </form>
  </div>
);
};

export default RequestDemoForm;
