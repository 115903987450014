// BackgroundContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const BackgroundContext = createContext({ isDark: true });

export const useBackground = () => useContext(BackgroundContext);

export const BackgroundProvider = ({ children }) => {
  const [isDark, setIsDark] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      // Toggle based on the approximate cycle of the background
      setIsDark((prev) => !prev);
    }, 5000); // Adjust this interval to match your background's cycle

    return () => clearInterval(interval);
  }, []);

  return (
    <BackgroundContext.Provider value={{ isDark }}>
      {children}
    </BackgroundContext.Provider>
  );
};
