import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import RequestDemoForm from "./components/RequestDemoForm";
import InsuranceDemoForm from "./components/InsuranceDemoForm";
import Pricing from "./components/Pricing";
import FloatingOrbButton from "./components/FloatingOrbButton";
import Background from './components/Background';
import Hero from './components/Hero';
import { BackgroundProvider } from './components/BackgroundContext'

// HomePage component structured with Tailwind CSS
const HomePage = () => {
  return (
    <div className="flex-grid">
      <div className="relative mx-auto text-center z-10 p-8 flex flex-col items-center justify-center overflow-hidden sm:p-12 lg:p-16 max-w-6xl">
        <Hero />
      </div>
      <div className="orb-button-container sticky flex justify-center z-30">
        <FloatingOrbButton />
      </div>
    </div>
  );
};

const App = () => {
  return (
    <BackgroundProvider>
    <Router>
    <div className="relative min-h-screen flex flex-col">
    <div className="absolute top-0 left-0 w-full h-full z-0">
          <Background />
        </div>

        <div className="sticky top-0 z-50">
          <Header />
        </div>

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/request-demo" element={<RequestDemoForm />} />
          <Route path="/insurance-demo" element={<InsuranceDemoForm />} />
          <Route path="/pricing" element={<Pricing />} />
          {/* Additional routes */}
        </Routes>
      </div>
    </Router>
    </BackgroundProvider>
  );
};

export default App;
