const insertStyleSheet = ({ idle, loading, active }) => {
  const styleSheet = document.createElement("style");
  styleSheet.type = 'text/css';

  // Corrected dynamic CSS strings to use passed configuration directly
  const boxShadowIdle = `0 0 10px ${idle.color}, 0 0 15px ${idle.hover || idle.color}, 0 0 10px ${idle.color}`;
  const boxShadowLoading = `0 0 80px 20px ${loading.color}, 0 0 120px 40px ${loading.color}`;
  const boxShadowActive = `0 0 20px ${active.color}, 0 0 40px ${active.color}`;

  styleSheet.innerText = `
    @keyframes bounce {
      30% { transform: translateY(0%); }
      40% { transform: translateY(-5%); }
      50% { transform: translateY(-10%); }
      60% { transform: translateY(-5%); }
      70% { transform: translateY(0%); }
    }
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    
    @keyframes pulse-animation {
      0% { box-shadow: 0 0 10px ${idle.color}; }
      50% { box-shadow: 0 0 15px ${idle.hover || idle.color}; }
      100% { box-shadow: 0 0 10px ${idle.color}; }
    }
    @keyframes float {
      0%, 100% { transform: translateY(0); }
      50% { transform: translateY(-10px); }
    }
    .vapi-btn {
      width: 100%;
      height: 100%;
      border-radius: 9999px;
      overflow: hidden;
      background: radial-gradient(54.75% 54.75% at 50% 50%, rgba(0, 0, 0, 0.22) 70.24%, rgba(230, 184, 92, 0.6) 100%),
                  linear-gradient(135deg, rgba(22, 35, 37, 0.54) 0%, rgba(230, 184, 92, 0) 100%),
                  radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.22) 0%, rgba(230, 184, 92, 0.65) 90.5%);
      background-blend-mode: normal, darken, normal;
      cursor: pointer;
      transform-origin: center;
      z-index: 20;
      animation: float 3s ease-in-out infinite; /* Apply the floating animation */
      transition: box-shadow 0.3s ease;
      box-shadow: ${boxShadowIdle};
    }
    
    .vapi-btn:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 0 40px rgba(230, 184, 92, 0.533), 0 0 80px rgba(230, 184, 92, 0.533);
    }
    
    .vapi-btn.is-speaking {
      animation: pulse-animation 1.5s infinite alternate;
    }
    
    .vapi-btn.is-loading {
      animation: spin 1s linear infinite;
      transform: translate(-50%, -50%) rotate(0deg);
      transform-origin: center;
      background: ${loading.color};
      box-shadow: ${boxShadowLoading};
    }
    
    
    .vapi-btn.is-active {
      background: ${active.color};
      box-shadow: ${boxShadowActive};
    }
  `;

  for (let i = 0; i <= 10; i++) {
    styleSheet.innerText += `
      .vapi-btn-volume-${i} {
        box-shadow: 1px 1px ${5 + i * 2}px ${i * 2}px ${active.color}, inset 0px 0px 10px 0px rgba(0,0,0,0.1);
      }
    `;
  }
  document.head.appendChild(styleSheet);
};

const createButtonElement = ({ idle = { color: 'rgb(230, 184, 92, 1)' }, loading = { color: 'rgb(230, 184, 92, 0.75)' }, active = { color: 'rgb(93, 254, 202)' } }) => {
  insertStyleSheet({ idle, loading, active });
  const button = document.createElement("div");
  button.className = "vapi-btn";
  button.onclick = () => {}; // Define the click behavior here
  document.body.appendChild(button); // Adds the button to the document
  return button;
};

const createButtonStateHandler = (config) => {
  return (button, state) => {
    const idleConfig = config.idle || {};
    const loadingConfig = config.loading || {};
    const activeConfig = config.active || {};

    // Reset to base class and apply new state class
    button.className = `vapi-btn vapi-btn-is-${state}`;

    // Reset styles that might change between states
    button.style.animation = '';
    button.style.background = '';
    button.style.boxShadow = '';

    // Ensure transform is consistently applied for positioning
    button.style.transform = 'translate(-50%, -50%)'; // Maintain position

    switch (state) {
      case 'idle':
        button.style.background = `radial-gradient(54.75% 54.75% at 50% 50%, rgba(0, 0, 0, 0.22) 70.24%, ${idleConfig.color} 100%)`;
        button.style.boxShadow = `0 4px 8px rgba(0, 0, 0, 0.2), 0 0 30px ${idleConfig.color}, 0 0 60px ${idleConfig.color}`;
        break;
      case 'loading':
        button.style.animation = "spin 1s linear infinite";
        button.style.transform = 'translate(-50%, -50%)'; // Reapply to ensure position
        button.style.transformOrigin = 'center'; // Center spinning animation
        button.style.background = `${loadingConfig.color}`;
        button.style.boxShadow = `0 0 80px 20px ${loadingConfig.color}, 0 0 120px 40px ${loadingConfig.color}`;
        break;
      case 'active':
        button.style.background = `${activeConfig.color}`;
        button.style.boxShadow = `0 0 20px ${activeConfig.color}, 0 0 40px ${activeConfig.color}`;
        break;
      default:
        console.warn(`Unhandled button state: ${state}`);
        // Optionally, set a default behavior here
        break;
    }
  };
};



export { createButtonElement, createButtonStateHandler };