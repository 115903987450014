import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Vapi from "@vapi-ai/web";
import defaultAssistant from "./assistant";
import { createButtonElement, createButtonStateHandler } from "./button";
import { defaultListeners } from "./listeners";

// Deep merge utility function
const deepMerge = (target, source) => {
  Object.keys(source).forEach((key) => {
    if (source[key] && typeof source[key] === 'object') {
      if (!target[key]) target[key] = {};
      deepMerge(target[key], source[key]);
    } else {
      target[key] = source[key];
    }
  });
  return target;
};

const runSDK = async ({
  apiKey = process.env.REACT_APP_VAPI_API,
  config = {},
}) => {
  if (!apiKey) {
    console.error("API Key is required.");
    return null;
  }

  try {
    const assistantConfig = defaultAssistant();

    const defaultConfig = {
      width: "192px",
      height: "192px",
      idle: {
        color: 'rgb(230, 184, 92)', // Default idle color
      },
      loading: {
        color: 'rgb(230, 184, 92)', // Default loading color
      },
      active: {
        color: 'rgb(255, 0, 0)', // Default active color
      },
    };

    const buttonConfig = deepMerge(defaultConfig, config);

    if (assistantConfig) {
      const vapi = new Vapi(apiKey);
      
      vapi.on("error", (error) => {
        console.error("Vapi error event:", error);
        // Handle the error appropriately
      });

      let startResult;
      try {
        startResult = await new Promise((resolve, reject) => {
          vapi.start(assistantConfig).then(resolve).catch(reject);
          
          // Set a timeout in case the start method hangs
          setTimeout(() => {
            reject(new Error("Vapi start timeout"));
          }, 10000); // 10 second timeout
        });
      } catch (startError) {
        console.error("Error starting Vapi:", startError);
        return null;
      }

      if (!startResult) {
        console.error("Vapi start failed to return a result");
        return null;
      }

      const buttonElement = createButtonElement(buttonConfig, "vapi-btn");
      document.body.appendChild(buttonElement);

      const buttonStateHandler = createButtonStateHandler(buttonConfig);
      defaultListeners(vapi, buttonElement, assistantConfig, buttonStateHandler);

      window.vapiSDK.vapi = vapi;
      return vapi;
    } else {
      console.error("Assistant Configuration is required.");
      return null;
    }
  } catch (error) {
    console.error("Error initializing SDK:", error);
    return null;
  }
};

// Properly initialize the root element for React 18+
const rootElement = document.getElementById("root");
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  console.error("Root element not found");
}

// Assign the runSDK function to the window object for global access
window.vapiSDK = {
  run: runSDK,
};
