import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

const Background = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    // Scene, Camera, Renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);

    // Add renderer to DOM
    mountRef.current.appendChild(renderer.domElement);

    // Plane Geometry
    const geometry = new THREE.PlaneGeometry(window.innerWidth, window.innerHeight, 1, 1);

    // Shader Material
    const vertexShader = `
      void main() {
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
      }
    `;
    const fragmentShader = `
  uniform float time;
  uniform vec2 resolution;

  void main() {
    vec2 position = gl_FragCoord.xy / resolution.xy;
    // Dark Blue background
    vec3 backgroundColor = vec3(47.0/255.0, 64.0/255.0, 74.0/255.0);
    // Dark Gray orb color
    vec3 orbColor = vec3(51.0/255.0, 51.0/255.0, 51.0/255.0);
    // Calculate radial gradient for orb effect
    float gradient = smoothstep(0.5, 0.2, distance(position, vec2(0.5)));
    // Mix the background and orb colors
    vec3 color = mix(orbColor, backgroundColor, gradient);
    // Apply dynamic effect
    float dynamic = 0.5 + 0.5 * cos(time);
    color *= dynamic;
    
    gl_FragColor = vec4(color, 1.0);
  }
`;




    
  const material = new THREE.ShaderMaterial({
    uniforms: {
      time: { value: 0.0 },
      resolution: { value: new THREE.Vector2(window.innerWidth, window.innerHeight) } // Pass the resolution to the shader
    },
    vertexShader,
    fragmentShader
  });
  

    // Mesh
    const mesh = new THREE.Mesh(geometry, material);
    scene.add(mesh);

    // Camera Position
    camera.position.z = 5;

    // Animation Loop
    const animate = () => {
      // This will create a loop that causes the renderer to draw the scene every time the screen is refreshed
      requestAnimationFrame(animate);
      material.uniforms.time.value = performance.now() / 1000; // Update the time uniform in the shader
      renderer.render(scene, camera);
    };

    animate();

    // Handle resizing
    // Inside the handleResize function
const handleResize = () => {
    const { width, height } = mountRef.current.getBoundingClientRect();
    renderer.setSize(width, height);
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
    material.uniforms.resolution.value.set(width, height); // Update the resolution uniform
  };
  

    window.addEventListener('resize', handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
      mountRef.current.removeChild(renderer.domElement); // Remove the renderer from the DOM
      scene.clear(); // Clear the Three.js scene
      geometry.dispose(); // Dispose of the geometry
      material.dispose(); // Dispose of the material
    };
  }, []);

  return <div ref={mountRef} className="background" />;
};

export default Background;
